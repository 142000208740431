import React from 'react';

import styles from './TileSectionCard.module.scss';
import ArrowButton from '../../shared/arrow-button/ArrowButton';
import { Link } from 'gatsby';
import useViewport from '../../../hooks/useViewport';

interface Props {
  title: string;
  image: string;
  index?: string;
  route: string;
}

const TileSectionCard: React.FC<Props> = ({ title, image, index, route }: Props) => {
  const viewport = useViewport();

  const fetchedAutoUrl = `${image.split('upload')[0]}${'upload/f_auto'}${image.split('upload')[1]}`;

  return (
    <Link className={styles.wrapper} to={route}>
      <div className={styles.delimiterContainer}>
        <div className={styles.delimiter}></div>
        <span className={styles.index}>{index}</span>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{title}</h1>
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.imageCover}></div>
          <div className={styles.imageHover}></div>
          <img className={styles.image} src={fetchedAutoUrl} alt={title} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div>
          <ArrowButton
            isWhite={true}
            imgHeight={'2.5rem'}
            name={viewport.isMobile ? 'SEE MORE' : 'SEE ALL'}
            imgLeftMargin={'3rem'}
          />
          {!viewport.isMobile && <span className={styles.buttonTitle}>{title.toUpperCase()}</span>}
        </div>
        <div className={styles.delimiter} />
      </div>
    </Link>
  );
};

export default TileSectionCard;
