import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { StrapiImage } from '../components/shared/interfaces';
import TileSectionCard from '../components/tile/tile-section-card/TileSectionCard';
import styles from './tile.module.scss';

export const query = graphql`
  query {
    strapi {
      tilePage {
        title
        tile_collections {
          id
        }
      }
      tileCollections {
        id
        name
        image {
          url
        }
        tile_collection_page {
          slug
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      tilePage: {
        title: string;
        tile_collections: Array<{
          id: number;
        }>;
      };
      tileCollections: Array<{
        id: number;
        name: string;
        image: StrapiImage;
        tile_collection_page: {
          slug: string;
        };
      }>;
    };
  };
}

const Tile: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  return (
    <Layout lightNavbar={true}>
      <SEO title={data.tilePage.title} />
      <section className={styles.wrapper}>
        <div className={styles.cardsContainer}>
          {data.tilePage.tile_collections.map(({ id }, i) => {
            const collection = data.tileCollections.find((p) => p.id === id);
            return (
              collection && (
                <div className={styles.card} key={`tile_collection_${collection.id}`}>
                  <TileSectionCard
                    image={collection.image?.url}
                    route={collection.tile_collection_page.slug}
                    title={collection.name}
                    index={0 + (i + 1).toString()}
                  />
                </div>
              )
            );
          })}
        </div>
      </section>
      <div className={styles.cork}></div>
    </Layout>
  );
};

export default Tile;
